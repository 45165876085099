import { Button, Form, Space, Typography } from "antd";
import { useRouter } from "next/router";
import { useState } from "react";
import { MfaTokenInput } from "@/components/authentication/MfaTokenInput";
import styles from "@/components/authentication/SignUp.module.scss";
import supabase from "@/features/supabase";
const {
  Text,
  Title
} = Typography;
type MfaVerifyType = {
  generateKeyPairs?: () => Promise<void>;
  onSuccess?: () => void;
};
const MfaVerify = ({
  generateKeyPairs,
  onSuccess
}: MfaVerifyType) => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const handleSubmit = async ({
    verifyCode = ""
  }: {
    verifyCode: string;
  }) => {
    setIsLoading(true);
    setError("");
    try {
      const factors = await supabase.auth.mfa.listFactors();
      if (factors.error) {
        throw new Error(factors.error.message);
      }
      const totpFactor = factors.data.totp[0];
      if (!totpFactor) {
        setIsLoading(false);
        throw new Error("No TOTP factor found.");
      }
      const factorId = totpFactor.id;
      const challenge = await supabase.auth.mfa.challenge({
        factorId
      });
      if (challenge.error) {
        throw new Error(challenge.error.message);
      }
      const challengeId = challenge.data.id;
      const verify = await supabase.auth.mfa.verify({
        factorId,
        challengeId,
        code: verifyCode.toString()
      });
      if (verify.error) {
        throw new Error(verify.error.message);
      }
      generateKeyPairs && (await generateKeyPairs());
      if (onSuccess) {
        onSuccess();
      } else {
        router.push("/");
      }
    } catch (e: unknown) {
      const err = e as Error;
      setError(err.message);
    }
    setIsLoading(false);
  };
  const handleLogOut = async () => {
    await supabase.auth.signOut();
  };
  return <>
      <Title level={2} className={styles.title} data-sentry-element="Title" data-sentry-source-file="MfaVerify.tsx">
        Enter verification code
      </Title>
      <Text type="secondary" className={styles.subtitle} data-sentry-element="Text" data-sentry-source-file="MfaVerify.tsx">
        Enter the 6-digit code generated by your authenticator app
      </Text>
      <Form layout="vertical" onFinish={handleSubmit} className={styles.form} data-sentry-element="Form" data-sentry-source-file="MfaVerify.tsx">
        <Form.Item label="" name="verifyCode" validateStatus={error && "error"} help={error} data-sentry-element="unknown" data-sentry-source-file="MfaVerify.tsx">
          <MfaTokenInput data-sentry-element="MfaTokenInput" data-sentry-source-file="MfaVerify.tsx" />
        </Form.Item>
        <Space direction="vertical" style={{
        width: "100%"
      }} data-sentry-element="Space" data-sentry-source-file="MfaVerify.tsx">
          <Button type="primary" block htmlType="submit" loading={isLoading} data-sentry-element="Button" data-sentry-source-file="MfaVerify.tsx">
            Verify
          </Button>
          <Button block onClick={handleLogOut} data-sentry-element="Button" data-sentry-source-file="MfaVerify.tsx">
            Log out
          </Button>
        </Space>
      </Form>
    </>;
};
export default MfaVerify;