import { Login } from "@/components/authentication/Login";
import { NextPageWithLayout } from "@/pages/_app";
import { store } from "@/store";
import { supabaseApi } from "@/store/services/supabase";
import { useKeyPair } from "@/utils/hooks/useKeyPair";
import { ReactElement, useEffect } from "react";
const LoginPage: NextPageWithLayout = () => {
  const [, {
    reset
  }] = useKeyPair();
  useEffect(() => {
    reset();
    invalidateSupabaseCache();
  }, []);
  const invalidateSupabaseCache = () => {
    store.dispatch(supabaseApi.util.invalidateTags([{
      type: "User"
    }, {
      type: "Domain"
    }, {
      type: "Group"
    }, {
      type: "Slug"
    }, {
      type: "Org"
    }, {
      type: "Portfolio"
    }, {
      type: "PortfolioAssets"
    }, {
      type: "KeyPair"
    }]));
  };
  return <Login data-sentry-element="Login" data-sentry-component="LoginPage" data-sentry-source-file="login.tsx" />;
};

// Login component provides AuthLayout since it configures it.
LoginPage.getLayout = (page: ReactElement) => {
  return page;
};
export default LoginPage;