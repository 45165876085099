import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Alert, Button, Form, Input, Typography } from "antd";
import Link from "next/link";
import { Captcha, CaptchaProps, CaptchaState } from "@/components/authentication/Captcha";
import styles from "@/components/authentication/Login.module.scss";
import MfaEnrol from "@/components/authentication/MfaEnrol";
import MfaVerify from "@/components/authentication/MfaVerify";
import AuthLayout from "@/components/layouts/AuthLayout";
import { AuthLayoutBrandingWrapper } from "@/components/layouts/AuthLayoutBrandingWrapper";
import { getCommsConfig } from "@/features/branding";
import supabase from "@/features/supabase";
import { useGetAssuranceLevelQuery } from "@/store/services/supabase";
import { formatTimestamp } from "@/utils/datetime";
import { useKeyPair } from "@/utils/hooks/useKeyPair";
import { passwordPreHash } from "@/utils/passwordPreHash";
import { useSession } from "@supabase/auth-helpers-react";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
const {
  Text,
  Title
} = Typography;
type ViewStates = "login" | "mfaEnrol" | "mfaVerify";
type LoginFormTypes = {
  email: string;
  password: string;
};
type LoginPromptProps = {
  successfulFlow: boolean;
  isLoading: boolean;
  handleSubmit: (values: LoginFormTypes) => void;
  setCaptchaToken: CaptchaProps["onSuccess"];
  setCaptchaRef: CaptchaProps["setCaptchaRef"];
  errorMsg: string;
};
const supportEmail = getCommsConfig().emailSupport.email;
export const Login = () => {
  const [view, setView] = useState<ViewStates>("login");
  const [captchaToken, setCaptchaToken] = useState<undefined | string>();
  const [captchaRef, setCaptchaRef] = useState<CaptchaState>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, {
    getOrGenerate
  }] = useKeyPair();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [password, setPassword] = useState("");
  const [successfulFlow, setSuccessfulFlow] = useState(false);
  const router = useRouter();
  const {
    t
  } = useTranslation("authentication");
  const session = useSession();
  const {
    data: assuranceLevel,
    refetch: refetchAssuranceLevel
  } = useGetAssuranceLevelQuery();
  useEffect(() => {
    setLoginView();
    // Password required for unwrapping or generating keys.
    const signOut = async () => await supabase.auth.signOut();
    !password && signOut();
  }, []);
  useEffect(() => {
    if (router.query.success === "true") {
      setSuccessfulFlow(true);
      supabase.auth.signOut();
      return;
    }
    setSuccessfulFlow(false);
  }, [router.query]);
  useEffect(() => {
    console.log("useEffect[assuranceLevel]: session: ", session);
    setLoginView();
  }, [assuranceLevel]);
  useEffect(() => {
    console.log("useEffect[session]: session: ", session);
    setLoginView();
  }, [session]);
  const handleSubmit = async (values: LoginFormTypes) => {
    setIsLoading(true);
    setErrorMsg("");
    console.log({
      captchaToken
    });
    const {
      error
    } = await supabase.auth.signInWithPassword({
      email: values.email,
      password: await passwordPreHash(values.password),
      options: {
        captchaToken
      }
    });
    setIsLoading(false);
    if (error) {
      // captcha token cannot be reused
      captchaRef?.current.reset();
      if (error.message.startsWith("captcha")) {
        setErrorMsg(t("captchaError") as string);
      } else if (error.message.startsWith("20")) {
        setErrorMsg(t("lockedUntilError", {
          time: formatTimestamp(error.message),
          supportEmail
        }) as string);
      } else if (/infinity|Error invoking/.test(error.message)) {
        setErrorMsg(t("passwordHookError", {
          supportEmail
        }) as string);
      } else {
        setErrorMsg(error.message);
      }
      return;
    }
    console.log("handleSubmit: session: ", session);
    // sessionStorage.clear();
    setPassword(values.password); // intentionally NOT preHashed b/c used to encrypt keyPair
    refetchAssuranceLevel();
  };
  const setLoginView = async () => {
    console.log("setLoginView: session: ", session);
    if (!session) {
      setView("login");
      return;
    }
    if (assuranceLevel && assuranceLevel.currentLevel === "aal1") {
      if (assuranceLevel.shouldEnrol) {
        setView("mfaEnrol");
      } else {
        setView("mfaVerify");
      }

      // TODO is this needed?
      if (assuranceLevel.usingOTP) {
        setView("login");
        console.log("useEffect[assuranceLevel.usingOTP]: session: ", session);
        await supabase.auth.signOut();
      }
    }
  };
  const generateKeyPairs = async () => {
    await getOrGenerate(password);
  };
  switch (view) {
    case "login":
      return <AuthLayout>
          <AuthLayoutBrandingWrapper>
            <LoginPrompt {...{
            successfulFlow,
            isLoading,
            handleSubmit,
            setCaptchaToken,
            setCaptchaRef,
            errorMsg
          }} />
          </AuthLayoutBrandingWrapper>
        </AuthLayout>;
    case "mfaVerify":
      return <AuthLayout>
          <MfaVerify generateKeyPairs={generateKeyPairs} />
        </AuthLayout>;
    case "mfaEnrol":
      return <AuthLayout formWidth="500px">
          <MfaEnrol generateKeyPairs={generateKeyPairs} />
        </AuthLayout>;
  }
};
const LoginPrompt = ({
  successfulFlow,
  isLoading,
  handleSubmit,
  setCaptchaToken,
  setCaptchaRef,
  errorMsg
}: LoginPromptProps) => {
  const {
    t
  } = useTranslation("pages", {
    keyPrefix: "login"
  });
  return <>
      {successfulFlow && <Alert message="Success! Please log in to continue." type="success" style={{
      marginBottom: "1rem",
      width: "100%"
    }} showIcon closable />}

      <Title level={1} className={styles.title} data-sentry-element="Title" data-sentry-source-file="Login.tsx">
        {t("title")}
      </Title>
      <Title level={2} className={styles.prompt} data-sentry-element="Title" data-sentry-source-file="Login.tsx">
        {t("subtitle")}
      </Title>

      <br />

      <Form className={styles.form} layout="vertical" requiredMark={false} onFinish={handleSubmit} data-sentry-element="Form" data-sentry-source-file="Login.tsx">
        <Form.Item name="email" rules={[{
        required: true,
        message: "Email is required"
      }]} data-sentry-element="unknown" data-sentry-source-file="Login.tsx">
          <Input prefix={<UserOutlined />} placeholder={t("form.email") || ""} data-sentry-element="Input" data-sentry-source-file="Login.tsx" />
        </Form.Item>
        <Form.Item name="password" rules={[{
        required: true,
        message: "Password is required"
      }]} data-sentry-element="unknown" data-sentry-source-file="Login.tsx">
          <Input.Password prefix={<LockOutlined />} type="password" placeholder={t("form.password") || ""} data-sentry-element="unknown" data-sentry-source-file="Login.tsx" />
        </Form.Item>

        <Captcha onSuccess={setCaptchaToken} setCaptchaRef={setCaptchaRef} data-sentry-element="Captcha" data-sentry-source-file="Login.tsx" />
        <Form.Item data-sentry-element="unknown" data-sentry-source-file="Login.tsx">
          <Button type="primary" block htmlType="submit" loading={isLoading} data-sentry-element="Button" data-sentry-source-file="Login.tsx">
            {t("form.button")}
          </Button>
        </Form.Item>
        {errorMsg && <Alert type="error" message={<div dangerouslySetInnerHTML={{
        __html: errorMsg
      }} />} className={styles.alert} />}

        <Text className={styles.forgotPassword} data-sentry-element="Text" data-sentry-source-file="Login.tsx">
          <Link href="forgot-password" data-sentry-element="Link" data-sentry-source-file="Login.tsx">Forgot password?</Link>
        </Text>

        {!process.env.NEXT_PUBLIC_THEME_NAME?.startsWith("hkma") && <Text style={{
        textAlign: "center"
      }}>
            Don&apos;t have an account? <Link href="https://xdi.systems/contact-sales">Request a Demo</Link>
          </Text>}
      </Form>
    </>;
};
export default Login;